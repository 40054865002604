<!-- 海报(卡片)有两种查看方式，'/poster'页面 及 课程或专题相关页面内的全屏弹窗 -->
<!-- 本组件即 /poster 页面 -->
<template>
  <div class="page-poster">
    <!-- 侧边栏 -->
    <p class="tips">
      <Icon :_type="'info'" :_size="15" :_color="'#a5a5a5'"></Icon>
      如无法保存，请关闭无痕模式，或更换浏览器进行下载
    </p>
    <div class="operation-btn mob" :class="isSpread?'isspread':''" @click="changeSpread">
      <span>设置</span>
    </div>
    <div class="operation-area" :class="isSpread?'isspread':''">
      <div class="operation-con">
        <div class="close-icon mob" @click.prevent="changeSpread">
          <Icon :_type="'close'" :_size="16" :_color="'#ffffff'"></Icon>
        </div>
        <h1>设置：</h1>
        <ul>
          <li data-desc="年份">
            <label ><span>*</span>选择年份：</label>
            <div class="operation-menus">
              <el-dropdown class="operation-menu act" trigger="click">
                <span class="menu-item">
                  {{ selectedParams.year && selectedParams.year.slice(0,4) }}{{ selectedParams.year && id_arr[selectedParams.year] && id_arr[selectedParams.year].address }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown" class="operation-menu-year">
                  <el-dropdown-item v-for="(item,index) in Object.values(id_arr).sort(global.toSortKey('sid',0,'string'))" :key="'+'+index">
                    <!-- <p @click.prevent="selectYear(item.sid)">{{ item.year }} {{ item.address }}</p> -->
                    <a :href="'/poster?sid='+item.sid" target="_self">
                      <p>{{ item.year }} {{ item.address }}</p>
                    </a>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </li>
          <li data-desc="类型">
            <label ><span>*</span>选择类型：</label>
            <div class="operation-menus">
              <div class="operation-menu" :class="(selectedParams.type==key)?'act':''" v-for="(item,key) in baseType" :key="'+'+key">
                <span class="menu-item" @click="selectType(key)">{{ item[0] }}</span>
              </div>
            </div>
          </li>
          <li data-desc="选项" v-if="selectedParams.cardId && selectedParams.type!=='case'">
            <label ><span>*</span>选择{{ selectedParams.type && baseType[selectedParams.type] && baseType[selectedParams.type][1] }}：</label>
            <div class="operation-menus" v-if="!((Array.isArray(_datalist[selectedParams.type]) && _datalist[selectedParams.type].length>0) || global.isObjectKeys(_datalist[selectedParams.type]))">
              <p class="operation-tips">加载中...</p>
            </div>
            <slot v-else>
              <div class="operation-menus" v-if="['chairman','producer'].includes(selectedParams.type)">
                <div class="operation-menu" :class="checkId(item.id)?'act':''" v-for="(item,index) in _datalist[selectedParams.type]" :key="selectedParams.type+'_'+index">
                  <span class="menu-item" @click="selectId(item.id)">{{ global.formatNumber(index+1) }}.{{ item.penName || item.name }}</span>
                </div>
              </div>
              <div class="operation-menus select-menus" v-if="selectedParams.type==='subject'">
                <div class="operation-menu" :class="checkId(item.id)?'act':''" v-for="(item,index) in _datalist['subject']" :key="'s'+index">
                  <span class="menu-item" @click="selectId(item.id)">{{ global.formatNumber(index+1) }}.{{ item.venueName }}</span>
                </div>
              </div>
              <div class="operation-menus select-menus" v-else-if="selectedParams.type==='course'">
                <div class="operation-menu" :class="checkId(item.courseid, item.teacher&&item.teacher[0]&&item.teacher[0].id)?'act':''" v-for="(item,index) in _datalist['course']" :key="'c'+index">
                  <span class="menu-item" @click="selectId(item.courseid, {'clid':item.teacher&&item.teacher[0]&&item.teacher[0].id})">{{ global.formatNumber(index+1) }}.{{ (item.teacher&&item.teacher[0]&&item.teacher[0].name&&item.teacher[0].name+' -') || '' }} {{ item.title }}</span>
                </div>
              </div>
              <div class="operation-menus" v-if="selectedParams.type==='schedule'">
                <div class="operation-menu" :class="checkId(index)?'act':''" v-for="(item,key,index) in _datalist['schedule']" :key="'sc'+key">
                  <span class="menu-item" @click="selectId(parseInt(key))">{{ '第'+(parseInt(key)+1)+'天' }}</span>
                </div>
                <p class="operation-tips">日程宣传图较长，故截图生成时间可能较长，<br>请耐心等候...</p>
              </div>
            </slot>
          </li>
          <li data-desc="无模板" v-if="!selectedParams.cardId">
            <label style="opacity:0;"><span>*</span>选择{{ selectedParams.type && baseType[selectedParams.type] && baseType[selectedParams.type][1] }}：</label>
            <div class="operation-menus">
              <p class="operation-tips">当前类型暂无模板</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 海报(卡片)预览 -->
    <Page 
      v-if="selectedParams.type && selectedParams.type.length>0 && isActive" 
      :_selectparams="JSON.stringify(selectedParams)" 
      :_info="JSON.stringify(dataActive)" 
      :_origininfo="_origindata" 
      @setinfo="setInfo" 
      :key="`${selectedParams.sid}_${selectedParams.type}_${selectedParams.cardId}_${selectedParams.cardDetailId||''}_${selectedParams.venueId||''}_${selectedParams.courseId||''}`" 
    ></Page>
  </div>
</template>

<script>
import { id_arr, sid, getSueType, getSueArr, getCardInfo, getSpeechInfoNew, getAllCourse, getScheduleInfo, scheduledifference, scheduleDifferenceId } from '../utils/api.js';

export default {
  name: 'Poster',
  props: { 
    _cardids: {
      type: Object,
      default: () => {}
    },
    _datalist: {
      type: Object,
      default: () => {}
    },
    _origindata: {
      type: [Object, Array],
      default: () => {}
    },
    _selectparams: [Object, String],
  },
  components: {
    Icon: ()=>import('../components/Icon.vue'),
    Page: () => import('../components/Card/Page.vue'),
  },
  data() {
    let _this = this;
    return {
      id_arr: id_arr,
      dataList: {},
      dataActive: {},
      selectedParams: {
        year: false,
        sid: false,
        type: false,
        cardId: false,
        venueId: false,
        courseId: false,
        scheduleId: false,
        lecturerId: false
      },
      baseType: { subject: ['专题详情','专题'], course: ['课程详情','课程'], case: ['精选案例','案例'], schedule: ['日程安排','日程'], chairman: ['联席主席','主席'], producer: ['出品人','出品人'] },
      secondaryKeys: { subject: 'slid', course: 'clid' }, // 专题/课程 对应的二级id的key
      isSpread: false,
      showCard: true,
      isActive: false,
    }
  },
  watch: {
    _datalist: {
      handler(newV, oldV) {
        // console.log('Poster·watch·_datalist ', newV, oldV);
        if(newV && typeof newV==='object') {
          this.dataList = this.global.deepClone(newV);
        }
      },
      immediate: true,
      deep: true,
    },
    _selectparams: {
      handler(newV, oldV) {
        // console.log('Poster·watch·_selectparams ', newV, oldV);
        if(newV && typeof newV==='string' && newV.includes('{')) {
          this.selectedParams = JSON.parse(newV);
        }
      },
      immediate: true,
      deep: true,
    },
    selectedParams: {
      handler(newV, oldV) {
        console.log('Poster·watch·selectedParams ', newV, oldV);
        if(newV) {
          if (!oldV || (typeof newV==='object' && typeof oldV==='object' && !(
            this.global.toCompareObject(newV,oldV) // detailid 和 id 分开判断，优先判断detailid 》》》》》》poster
          ))) {
            this.isActive = false;
            // if(newV.type=='schedule' && oldV && (newV.cardId==oldV.cardId)) {
            //   this.isActive = true;
            // } else {
            //   this.dataActive = {};
            //   this.getActiveData(newV);
            // }
            this.dataActive = {};
            this.getActiveData(newV);
          }
        }
      },
      immediate: true,
      deep: true,
    },
    // _origindata: {
    //   handler(newV, oldV) {
    //     if(newV) {
    //       // this.dataActive = newV;
    //     }
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    isActive: {
      handler(newV, oldV) {
        // console.log('Poster·watch·isActive ', newV, oldV);
        if (newV) { // isActive为真时dataActive不为空,可返回
          this.$emit('dataactive', this.dataActive);
        }
      },
      immediate: true,
      deep: true,
    },
    // dataActive: {
    //   handler(newV, oldV) {
    //     console.log('Poster·watch·dataActive ', newV, oldV);
    //   },
    //   immediate: true,
    //   deep: true,
    // },
  },
  created() {
    // 
  },
  methods: {
    // 专题/课程/案例/日程 active
    checkId(id, secondaryId) {
      // console.log(this.selectedParams.type, '-', this.selectedParams.venueId, '-', this.selectedParams.courseId, '-', id);
      let flag = false;
      // secondaryId
      let sKey = this.selectedParams.type && this.secondaryKeys[this.selectedParams.type]; // 》》》》》》poster
      if(this.selectedParams.type == 'chairman' || this.selectedParams.type == 'producer') {
        if(this.selectedParams.lecturerId == id) {
          if(this.selectedParams.lecturerId == id) {
            flag = true;
          }
        } else {
          if(this.dataActive && this.dataActive.lecturer && this.dataActive.lecturer.lecturerId == id) {
            flag = true;
          }
        }
      }
      if(this.selectedParams.type == 'subject') {
        if(this.selectedParams.venueId) {
          if(this.selectedParams.venueId == id) {
            flag = true;
          }
        } else {
          if(this.dataActive && this.dataActive.venue && this.dataActive.venue.svId == id) {
            flag = true;
          }
        }
      }
      if(this.selectedParams.type == 'course') {
        if(this.selectedParams.courseId) {
          if(this.selectedParams.courseId == id) {
            if (!sKey || !this.selectedParams[sKey] || (secondaryId && this.selectedParams[sKey]==secondaryId)) {
              flag = true;
            }
          }
        } else {
          if(this.dataActive && this.dataActive.course && this.dataActive.course.courseId == id) {
            flag = true;
          }
        }
      }
      if(this.selectedParams.type == 'schedule') {
        if(this.selectedParams.scheduleId) {
          if(this.selectedParams.scheduleId!==false && this.selectedParams.scheduleId==id) {
            flag = true;
          }
        } else {
          if(id == 0) {
            flag = true;
          }
        }
      }
      return flag;
    },
    // mob端开合侧边栏
    changeSpread() {
      // console.log(this.isSpread);
      this.isSpread = !this.isSpread;
      if(this.isSpread) {
        $('.page-poster .operation-area .operation-con ul').scrollTop(0);
      }
      this.$forceUpdate();
    },

    // 选择年份
    selectYear(key) {
      let origin = this.selectedParams.year;
      let year_new = '';
      for(let y in this.id_arr) {
        if(this.id_arr[y].sid == key) {
          year_new = y;
          break;
        }
      }
      this.selectedParams.year = year_new;
      // this.global.year = year_new;
      this.changeSelect('year', [[origin, year_new]] );
    },
    // 选择类型
    selectType(type) {
      let origin = this.selectedParams.type;
      // this.selectedParams.type = type;
      this.changeSelect('type', [[origin, type]] );
    },
    // 选择专题/课程/案例/日程
    selectId(id, secondary) {
      // console.log('Poster·method·select_id ', id, this.selectedParams)
      let origin = '';
      if(this.selectedParams.type == 'chairman' || this.selectedParams.type == 'producer') {
        origin = this.selectedParams.lecturerId;
        // this.selectedParams.lecturerId = id;
      }
      if(this.selectedParams.type == 'subject') {
        origin = this.selectedParams.venueId;
        // this.selectedParams.venueId = id;
      }
      if(this.selectedParams.type == 'course') {
        origin = this.selectedParams.courseId;
        // this.selectedParams.courseId = id;
      }
      if(this.selectedParams.type == 'schedule') {
        origin = this.selectedParams.scheduleId;
        // this.selectedParams.scheduleId = id;
      }
      // key
      let sKey = this.selectedParams.type && this.secondaryKeys && this.secondaryKeys[this.selectedParams.type]
      let secondaryOption = []
      if (sKey && secondary && secondary[sKey]) {
        if (this.selectedParams[sKey] !== secondary[sKey]) {
          secondaryOption = [{sKey: this.selectedParams[sKey]||''}, secondary]
        }
      }
      this.changeSelect('id', [[origin, id], secondaryOption]);
    },

    // 选择完成 -> 切换数据/路由跳转
    refreshWin(param) {
      // window.open(`/poster${param}`, '_self'); // 强制刷新-初始化全部数据
      // this.$router.push(`/poster${param}`)
      this.$router.replace(`/poster${param}`)
    },
    changeSelect(_method, options) {
      // console.log('Poster·method·change_select ', _old, _new, _method, this._cardids, this.selectedParams)
      let _old = (options && options[0] && options[0][0]) || null
      let _new = (options && options[0] && options[0][1]) || null
      let _old_secondary = (options && options[1] && options[1][0]) || null
      let _new_secondary = (options && options[1] && options[1][1]) || {}
      if(_new && !(_old == _new) || (this.global.isObjectKeys(_new_secondary) && !this.global.toCompareObject(_old_secondary, _new_secondary))) {
        this.selectedParams.cardDetailId = false;
        /* year/type需要window.open()或$router强制刷新参数 */
        let href = '';
        if(_method === 'year' && (_old != _new)) {
          // this.$router.replace({path:'/poster', query:{sid:id_arr[_new].sid}});
          // href = `?sid=${id_arr[_new].sid}&type=${this.selectedParams.type||'subject'}`;
          // this.selectedParams.type = 'subject';
          // href = `?sid=${id_arr[_new].sid}&type=subject`;
          href = `?sid=${id_arr[_new].sid}`;
          this.refreshWin(href);
        }
        if(_method === 'type' && (_old != _new)) {
          /* 1、window.open() 强制刷新页面 */
          // href = `?sid=${this.selectedParams.sid}&type=${_new}`;
          // if(_new==='case' && this._cardids[this.selectedParams.sid] && this._cardids[this.selectedParams.sid]['casedetailid']) {
          //   href += `&detailid=${this._cardids[this.selectedParams.sid]['casedetailid']}`;
          // }
          // this.refreshWin(href);
          /* 2、$emit+watch使父组件切换type 不用刷新页面 父组件增加处理方法 */
          // this.$emit('typechange', _new);
          /* 3、router+props+watch使父组件切换type 不用刷新页面 父组件原props-watch方法修改即可不必再增加处理方法 */
          // let type_query = { sid: this.selectedParams.sid, type: _new, cardid: this._cardids[this.selectedParams.sid] && this._cardids[this.selectedParams.sid][_new] || '' };
          // if(_new === 'case') {
          //   type_query.detailid = this._cardids[this.selectedParams.sid] && this._cardids[this.selectedParams.sid]['casedetailid'] || '';
          // }
          // this.$router.replace({ path: '/poster', query: type_query });
          let href = `?sid=${this.selectedParams.sid}&type=${_new}&cardid=${(this._cardids[this.selectedParams.sid] && this._cardids[this.selectedParams.sid][_new]) || ''}`
          if (_new === 'case') {
            href += `&detailid=${(this._cardids[this.selectedParams.sid] && this._cardids[this.selectedParams.sid]['casedetailid']) || ''}`
          }
          this.refreshWin(href);
        }
        /* subject/course/schedule切换子项(专题/课程/日程)不需要window.open(),通过$emit()切换id参数即可 */
        if(_method === 'id') {
          let idParam = {type:this.selectedParams.type, id:_new, ..._new_secondary}
          this.$emit('idchange', idParam);
        }
      }
    },

    // 获取卡片数据 (from mrm)
    getActiveData(_params) {
      // console.log('Poster·method·get_active_data ', _params);
      if(_params.sid && _params.cardId && (_params.type==='schedule' || _params.cardDetailId || _params.courseId || _params.venueId || _params.scheduleId || _params.lecturerId)) {
        let params = `?sid=${_params.sid}&cardId=${_params.cardId}`;
        if (_params.cardDetailId) {
          params += `&cardDetailId=${_params.cardDetailId||''}`;
        } else {
          if (_params.type==='course') {
            params += `&courseId=${_params.courseId||''}`
          } else if (_params.type==='subject') {
            params += `&venueId=${_params.venueId||''}`
          } else if (['chairman','producer'].includes(_params.type)) {
            params += `&lecturerId=${_params.lecturerId||''}`;
          }
        }
        this.$http.jsonp(getCardInfo+params)
        .then(res => {
          return res.json();
        })
        .then(res => {
          console.log('Poster·method·get_active_data------ ', res);
          if(res && res.status=='200' && res.data) {
            this.dataActive = this.global.deepClone(res.data);
            this.isActive = true;
          }
        })
      }
    },

    setInfo(res) {
      console.log('👌🏻act-poster ', res);
      this.isSpread = false;
      // this.changeSpread();
      // this.$emit('idchange', {type:this.selectedParams.type, id:this.selectedParams.venueId});
      // 
      // if(res.type && res.data) {
      //   if(res.type == 'subject') {
      //     this.selectedParams.venueId = res.data && res.data.venue && res.data.venue.svId || false;
      //   }
      //   if(res.type == 'course') {
      //     this.selectedParams.courseId = res.data && res.data.course && (res.data.course.courseId || res.data.course.courseid) || false;
      //   }
      //   if(res.type == 'schedule') {
      //     this.selectedParams.scheduleId = res.data && res.data.schedule && (res.data.schedule.id!==false) ? res.data.schedule.id : false; 
      //   }
      // }
    },




    // 选择完成->格式化参数路由跳转
    _change_Select(_old, _new, _method) {
      if(_method === 'year') {
        this.selectedParams.type = 'subject';
      }
      if(_method === 'year' || _method === 'type') {
        this.selectedParams.venueId = '';
        this.selectedParams.courseId = '';
        this.dataList.active = {};
        this.dataList.origin = {};
      }
      if(!(_old === _new)) {
        let path = this.$route.path;
        let name = this.$route.name;
        let query = {
          sid: this.$route.query && this.$route.query.sid || '',
          type: this.$route.query && this.$route.query.type || this.selectedParams.type || '',
          cardid: this.$route.query && this.$route.query.cardid || this.allCardIds[this.selectedParams.year][this.selectedParams.type] || ''
        };
        // 设置 id 和 detailid
        if(this.selectedParams.type=='subject' && this.selectedParams.venueId) {
          query.id = this.selectedParams.venueId;
        } else if(this.selectedParams.type=='course' && this.selectedCourseId) {
          query.id = this.selectedCourseId;
        }
        query.detailid = this.selectedParams.type==='case' ? this._cardids[this.selectedParams.sid]['casedetailid'] : '';
        // query.detailid = this.selectedParams.cardDetailId || '';
        // console.log(path, name, query);
        this.$router.replace({ path, name, query });
      }
    },
    // 获取海报(卡片)数据-包括图片和文字数据
    _set_Info(res) {
      // console.log('👌🏻act ', res, this.dataList);
      if(res.data){
        this.showCard = false;
        this.dataList.active = res.data;
        // this.dataList.origin = {};
        // if(res.type == 'subject') {
        //   this.selectedParams.venueId = res.data.venue && res.data.venue.svId || '';
        // }
        // if(res.type == 'course') {
        //   this.selectedCourseId = res.data.course && res.data.course.courseId || '';
        // }
        // if(res.type == 'case') {
        //   this.selectedCaseId = res.data.case && Object.keys(res.data.case) && Object.keys(res.data.case).join(',') || '';
        // }
        // if(res.type == 'schedule') {
        //   // 
        // }
        this.showCard = true;
      }
      this.$forceUpdate();
    },
  }
}
</script>
